body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar-track {
  background-color: theme('colors.primary.100');
  border-left: 1px solid #ededed;
}
::-webkit-scrollbar {
  width: 7px;
  background-color: theme('colors.primary.300');
}
::-webkit-scrollbar:horizontal {
  height: 7px;
}
::-webkit-scrollbar-thumb {
  background: theme('colors.primary.300');
}
div::-webkit-scrollbar {
  width: 10px;
}
div::-webkit-scrollbar:horizontal {
  height: 10px;
}
